import React from 'react';

let visible = false;

const navDropdown = ({children, to, customClass}) => (
  <div class="nav-dropdown-wrapper" name={customClass ||'menubtn-dropdown'}>
    <button id="nav-dropdown-trigger" onClick={testFunction}>
      ▼
    </button>
    <div class="nav-dropdown-tooltip">
        {children || "Not defined"}
    </div>
  </div>
)

function testFunction(event) {
  console.log('test')
};


export default navDropdown;